
import { defineComponent, ref } from 'vue'
import {
  HomeOutlined,
  UserAddOutlined,
  ImportOutlined,
  GroupOutlined
} from '@ant-design/icons-vue'

import ListCandidate from '@/components/client/candidate/ListCandidate.vue'

export default defineComponent({
  components: {
    ListCandidate,
    HomeOutlined,
    UserAddOutlined,
    ImportOutlined,
    GroupOutlined
  },
  setup () {
    const params = new URLSearchParams(window.location.search).get('loginAs')
    const loginAs = ref(params ? '?loginAs=' + params : '')
    return {
      loginAs
    }
  }
})
