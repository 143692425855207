
import { defineComponent, ref, computed, unref, createVNode, onMounted } from 'vue'
import { ColumnProps } from 'ant-design-vue/es/table/interface'
import { notification, Modal } from 'ant-design-vue'
import { CloseCircleOutlined, SendOutlined, LoginOutlined } from '@ant-design/icons-vue'
import IProfile from '../../../interface/profile'

import profileService from '../../../services/profile'
import commonServices from '../../../services/common'
import router from '../../../router'
import searchService from '../../../services/filter'

type Key = ColumnProps['key'];
export default defineComponent({
  components: {
    SendOutlined,
    LoginOutlined
  },
  setup () {
    const loading = ref<boolean>(false)
    const candidates = ref<Array<any>>([])
    const params = new URLSearchParams(window.location.search).get('loginAs')
    const loginAs = ref(params ? '?loginAs=' + params : '')
    const columns = [
      {
        title: 'Name',
        onFilter: (value, record) => record.first_name.indexOf(value) === 0,
        sorter: (a, b) => a.first_name.length - b.first_name.length,
        sortDirections: ['descend', 'ascend'],
        slots: { customRender: 'nameSection' }
      },
      {
        title: 'Email',
        dataIndex: 'email',
        onFilter: (value, record) => record.email.indexOf(value) === 0,
        sorter: (a, b) => a.email.length - b.email.length,
        sortDirections: ['descend', 'ascend'],
        scopedSlots: { customRender: 'email' }
      },
      {
        title: 'Allowed Courses',
        dataIndex: 'total_course',
        align: 'center'
      },
      {
        title: 'Action',
        key: 'action',
        align: 'center',
        slots: { customRender: 'action' }
      }
    ]
    const selectedRowKeys = ref<Key[]>([])
    const showResendButton = ref<boolean>(false)
    const searchString = ref<string>('')
    const candidateData = ref<Array<IProfile>>([])
    const onSearch = (searchValue: string) => {
      console.log('use value', searchValue)
      candidates.value = searchService.search(candidateData.value, searchValue)
    }
    const getCandidates = async () => {
      const clientId = commonServices.getClientId()
      const responce = await profileService.getCandidates(clientId)
      candidates.value = responce.data
      candidateData.value = responce.data
    }
    const showLoginConfirm = async (profile) => {
      console.log('profile', profile)
      Modal.confirm({
        title: 'Are you sure to login as candidate?',
        icon: createVNode(LoginOutlined),
        content: createVNode('div', { style: 'color:red;' }, 'Are you sure want to login as candidate?. This will login the user in new window.'),
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        class: 'delte-confirm-modal',
        async onOk () {
          localStorage.setItem('loginAsProfile', JSON.stringify(profile))
          window.open('/candidate/course?loginAs=candidate', '_blank')
        },
        onCancel () {
          console.log('Cancel')
        }
      })
    }
    const editCandidate = async (candidate) => {
      router.push(`/client/candidates/edit/${candidate._id}${loginAs.value}`)
    }
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({ message, description })
    }
    const deleteCandidate = async (profile, index) => {
      try {
        candidates.value.splice(index, 1)
        const clientId = commonServices.getClientId()
        const subscriptionId = commonServices.getCurrentSubscription()._id
        const subscription = await profileService.deleteCandidate({ profileId: profile._id, clientId: clientId, subscriptionId: subscriptionId })
        localStorage.subscription = JSON.stringify(subscription.data)
        await notify('Success', 'Client deleted successfully', 'success')
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const confirmDeleteCandidate = async (profile, index) => {
      Modal.confirm({
        title: 'Are you sure?',
        icon: createVNode(CloseCircleOutlined),
        content: createVNode('div', {}, 'Do you really want to delete this record?. This process cannot be undo.'),
        okText: 'Delete',
        okType: 'danger',
        cancelText: 'Cancel',
        class: 'delte-confirm-modal',
        async onOk () {
          await deleteCandidate(profile, index)
        },
        onCancel () {
          console.log('Cancel')
        }
      })
    }
    const resendCredentials = async () => {
      try {
        loading.value = true
        await profileService.resendCredentials({ profileIds: selectedRowKeys.value })
        showResendButton.value = false
        loading.value = false
        selectedRowKeys.value = []
        await notify('Success', 'Login credentials send successfully', 'success')
      } catch (error) {
        loading.value = false
        await notify('Error', error.data, 'error')
      }
    }
    // select table rows checkbox
    const onSelectChange = (changableRowKeys: Key[]) => {
      console.log('selectedRowKeys changed: ', changableRowKeys)
      selectedRowKeys.value = changableRowKeys
      if (changableRowKeys.length > 0) {
        showResendButton.value = true
      } else {
        showResendButton.value = false
      }
    }
    const rowSelection = computed(() => {
      return {
        selectedRowKeys: unref(selectedRowKeys),
        onChange: onSelectChange,
        hideDefaultSelections: true,
        selections: [
          {
            key: 'all-data',
            text: 'Select All Data',
            onSelect: (changableRowKeys: Key[]) => {
              console.log('changableRowKeys', changableRowKeys)
              let newSelectedRowKeys = []
              newSelectedRowKeys = changableRowKeys
              selectedRowKeys.value = newSelectedRowKeys
            }
          },
          {
            key: 'odd',
            text: 'Select Odd Row',
            onSelect: (changableRowKeys: Key[]) => {
              let newSelectedRowKeys = []
              newSelectedRowKeys = changableRowKeys.filter((key, index) => {
                if (index % 2 !== 0) {
                  return false
                }
                return true
              })
              selectedRowKeys.value = newSelectedRowKeys
            }
          },
          {
            key: 'even',
            text: 'Select Even Row',
            onSelect: (changableRowKeys: Key[]) => {
              let newSelectedRowKeys = []
              newSelectedRowKeys = changableRowKeys.filter((key, index) => {
                if (index % 2 !== 0) {
                  return true
                }
                return false
              })
              selectedRowKeys.value = newSelectedRowKeys
            }
          }
        ]
      }
    })
    onMounted(() => {
      getCandidates()
    })
    return {
      columns,
      onSearch,
      searchString,
      editCandidate,
      selectedRowKeys,
      rowSelection,
      confirmDeleteCandidate,
      notify,
      candidates,
      showResendButton,
      resendCredentials,
      loading,
      loginAs,
      showLoginConfirm
    }
  }
})
