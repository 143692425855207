<template>
  <div class="main">
    <div class="user content-body">
      <a-breadcrumb>
        <a-breadcrumb-item href="">
          <HomeOutlined />
        </a-breadcrumb-item>
        <a-breadcrumb-item> Candidates</a-breadcrumb-item>
      </a-breadcrumb>

      <a-row class="header">
        <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div></div>
          <div class="add">
            <a-button type="primary">
              <router-link :to="'/client/candidates/add' + loginAs"><UserAddOutlined />  Add New
              </router-link>
            </a-button>
            <a-button type="primary">
              <router-link :to="'/client/candidates/import' + loginAs"><ImportOutlined />  Import
              </router-link>
            </a-button>
            <a-button type="primary">
              <router-link :to="'/client/candidate_group' + loginAs"><GroupOutlined />  Group
              </router-link>
            </a-button>
          </div>
        </a-col>
      </a-row>
      <ListCandidate />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import {
  HomeOutlined,
  UserAddOutlined,
  ImportOutlined,
  GroupOutlined
} from '@ant-design/icons-vue'

import ListCandidate from '@/components/client/candidate/ListCandidate.vue'

export default defineComponent({
  components: {
    ListCandidate,
    HomeOutlined,
    UserAddOutlined,
    ImportOutlined,
    GroupOutlined
  },
  setup () {
    const params = new URLSearchParams(window.location.search).get('loginAs')
    const loginAs = ref(params ? '?loginAs=' + params : '')
    return {
      loginAs
    }
  }
})
</script>

<style lang="scss">
.user {
  .header {
    background: #fff;
    padding: 15px;
    margin: 20px 10px 10px;
    border-radius: 10px;
    .add {
      float: right;
      .ant-btn {
        background-color: #7EAF1A;
        border-color: #7EAF1A;
        border-radius: 5px;
        font-family: "TT Norms Pro Medium";
        margin-left: 10px;
        a {
          color: #ffffff;
        }
      }
    }
  }
}

</style>
